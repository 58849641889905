import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import SkincareQuizComponent from "../../components/skincare-quiz/SkincareQuizComponent";

const SkincareQuiz = () => {
  return (
    <Layout>
      <Seo title="Skin Care Quiz" />
      <SkincareQuizComponent />
    </Layout>
  );
};

export default SkincareQuiz;
