import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  SimpleGrid,
  Spinner,
  Text,
  useBoolean,
  Container,
  Grid,
  GridItem,
  Image,
  Button,
  useMediaQuery,
  Modal
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import qore from "../../context/qoreContext";
import styled from "styled-components";
import DOMPurify from "dompurify";
import BgQuiz from "../../images/bg-skincare-quiz.png";
import BgQuizStart from "../../images/sc-quiz-start-desktop.png";
import ProductsImage from "../../images/skincare-products.png";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import ProductModal from "../product/ProductModal";
import Logo from "../../images/omg-logo2.png";

const QuizContainer = styled.div`
  background-image: url(${BgQuiz});
  background-size: 100% 100%;
  min-height: 80vh;
`;

const createMarkup = html => {
  return {
    __html: DOMPurify.sanitize(html)
  };
};

const ResultArticle = styled(Box)`
  text-align: justify;
  line-height: 24px;
  font-size: 20px;

  p {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

const defaultResultList = [
  {
    name: "normal",
    totalPoint: 0
  },
  {
    name: "kombinasi",
    totalPoint: 0
  },
  {
    name: "kering",
    totalPoint: 0
  },
  {
    name: "berminyak",
    totalPoint: 0
  }
];

const SkincareQuizComponent = () => {
  const [quizStart, setQuizStart] = useBoolean(false);
  const [quizFinish, setQuizFinish] = useBoolean(false);
  const [quizDisplay, setQuizDisplay] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [resultList, setResultList] = useState(defaultResultList);
  const [resultKey, setResultKey] = useState("");
  const [number, setNumber] = useState(0);
  const [modalShow, setModalShow] = useBoolean(false);
  const [popupData, setPopupData] = useState({});
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const { data: dataQuiz, status: statusQuiz } = qore.view("activeSkincareQuiz").useListRow({
    "$by.index": "asc"
  });

  const { data: dataResult, status: statusResult } = qore.view("allQuizSkincareResult").useListRow({
    skinType: resultKey
  });

  const { data: dataProducts } = qore.view("skincareProducts").useListRow({});

  const handleScore = data => {
    const tempArr = [...resultList];
    const target = tempArr.find(o => o.name === data.name);
    if (target) {
      target.totalPoint += data.value;
      setResultList(tempArr);
    }
  };

  const handleNext = currentNumber => {
    const index = currentNumber + 1;
    if (index < dataQuiz.length) {
      setQuizDisplay(dataQuiz[index]);
      setNumber(index);
    } else {
      setQuizStart.off();
      setQuizFinish.on();
    }
  };

  const handleClick = (currentNumber, data) => {
    handleScore(data);
    handleNext(currentNumber);
  };

  const capitalize = string => {
    return "Kulit " + string[0].toUpperCase() + string.slice(1);
  };

  const handleTryAgain = () => {
    setQuizStart.on();
    setQuizFinish.off();
    setQuizDisplay(dataQuiz[0]);
    setNumber(0);
    setResultList([
      {
        name: "normal",
        totalPoint: 0
      },
      {
        name: "kombinasi",
        totalPoint: 0
      },
      {
        name: "kering",
        totalPoint: 0
      },
      {
        name: "berminyak",
        totalPoint: 0
      }
    ]);
  };

  const handleClose = () => {
    setPopupData({});
    setModalShow.off();
  };

  const handleOpen = () => {
    const product = dataProducts[0];
    const obj = {
      name: "OMG Oh My Glow",
      imgSwatch: ProductsImage,
      tokopedia: product.tokopedia,
      shopee: product.shopee,
      lazada: product.lazada,
      blibli: product.blibli,
      tiktok: product.tiktok,
      whatsapp: product.whatsapp,
      isSachet: product.isSachet,
      type: product.type,
      toko: product.toko
    };
    setPopupData(obj);
    setModalShow.on();
  };

  useEffect(() => {
    if (dataQuiz.length) {
      setQuizDisplay(dataQuiz[0]);
    }
  }, [dataQuiz]);

  useEffect(() => {
    if (dataResult.length) {
      setQuizResult(dataResult[0]);
    }
  }, [dataResult]);

  useEffect(() => {
    if (quizFinish) {
      const handleSort = () => {
        let sorted = resultList.sort((a, b) => parseFloat(b.totalPoint) - parseFloat(a.totalPoint));
        setResultKey(() => sorted[0].name);
      };

      handleSort();
    }
  }, [quizFinish]);

  return (
    <>
      <Box minH={{ lg: "480px" }}>
        {!quizStart ? (
          <>
            {!quizFinish ? (
              <SimpleGrid
                backgroundImage={`url(${BgQuizStart})`}
                bgRepeat="no-repeat"
                bgSize="cover"
                columns={isMobile ? 1 : 2}
                spacing={0}>
                <Box
                  display={!isMobile && "flex"}
                  justifyContent={!isMobile && "flex-end"}
                  flexDirection={!isMobile && "column"}
                  minH={{ md: "480px", lg: "480px" }}
                  pt={{ xl: 8 }}
                  py={{ base: 10, md: 0 }}
                  px={{ base: 4, md: 0 }}>
                  {isMobile ? (
                    <SimpleGrid columns={2} spacing={0}>
                      <StaticImage
                        layout="fullWidth"
                        objectFit="fill"
                        src="../../images/skincare-quiz-model2.png"
                        alt="quiz-banner"
                        loading="eager"
                      />
                      <Box
                        textAlign={{ base: "left", md: "center" }}
                        color="#F86563"
                        fontWeight="semibold">
                        <Text fontSize={{ base: "20px", md: "24px" }}>
                          Are You Ready to Get Your
                        </Text>
                        <Text
                          fontSize={{ base: "44px", md: "48px" }}
                          lineHeight={{ base: "48px" }}
                          mt={{ base: 2, md: 0 }}>
                          Skin Goals
                        </Text>
                      </Box>
                    </SimpleGrid>
                  ) : (
                    <StaticImage
                      layout="fullWidth"
                      objectFit="fill"
                      src="../../images/skincare-quiz-model2.png"
                      alt="quiz-banner"
                      loading="eager"
                    />
                  )}
                </Box>
                <Box
                  pb={{ base: 10, md: 0 }}
                  margin={isMobile && "0 auto"}
                  position={isMobile ? "block" : "relative"}>
                  <Box
                    position={isMobile ? "block" : "absolute"}
                    top={isMobile ? "auto" : "50%"}
                    transform={isMobile ? "auto" : "translateY(-50%)"}
                    maxWidth="320px">
                    {!isMobile && (
                      <Box mb={10} textAlign="center" color="#F86563" fontWeight="semibold">
                        <Text>Are You Ready to Get Your</Text>
                        <Text fontSize="48px">Skin Goals</Text>
                      </Box>
                    )}
                    <Image
                      borderRadius="full"
                      boxSize="68px"
                      objectFit="contain"
                      src={Logo}
                      alt="omg-logo"
                      position={"absolute"}
                      bottom={"180px"}
                      right="120px"
                      border="4px solid white"
                    />
                    <Box rounded="2xl" p={10} border="6px solid white">
                      <Box>
                        <Text mb={6} textAlign="center" fontSize="22px" fontWeight="semibold">
                          Skin care yang cocok sama kulitku?
                        </Text>
                        <Button
                          onClick={() => setQuizStart.on()}
                          w="100%"
                          rounded="2xl"
                          bgColor="#000"
                          fontSize="20px"
                          colorScheme="blackAlpha"
                          _focus={{ boxShadow: "none" }}
                          _hover={{
                            bg: "white",
                            color: "black"
                          }}>
                          Coba Quiz
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </SimpleGrid>
            ) : (
              <QuizContainer>
                <Container
                  pb={{ base: "120px", md: "64px" }}
                  maxW={{ lg: "container.md", xl: "container.lg" }}
                  pt={8}>
                  <Box bgColor="white">
                    {statusResult === "success" && !_.isEmpty(quizResult) ? (
                      <Grid p={4} minH="400px" templateColumns="repeat(5, 1fr)" gap={4}>
                        <GridItem colSpan={{ base: 5, md: 2 }}>
                          {isMobile && (
                            <Text
                              mb={4}
                              textAlign="left"
                              fontSize={{ base: "24px" }}
                              fontWeight="semibold">
                              {capitalize(quizResult?.skinType)}
                            </Text>
                          )}
                          <Image
                            src={quizResult.img}
                            alt="quiz-result-model"
                            loading="lazy"
                            objectFit={"cover"}
                          />
                        </GridItem>
                        <GridItem colSpan={{ base: 5, md: 3 }}>
                          {!isMobile && (
                            <Text
                              mb={4}
                              textAlign="left"
                              fontSize={{ md: "40px" }}
                              fontWeight="semibold">
                              {capitalize(quizResult?.skinType)}
                            </Text>
                          )}
                          <ResultArticle
                            dangerouslySetInnerHTML={createMarkup(quizResult.description)}
                          />
                        </GridItem>
                        <GridItem bgColor={"black"} borderRadius="lg" colSpan={5}>
                          <Grid
                            p={{ base: 3, md: 8 }}
                            pb={{ base: 3, md: 0 }}
                            templateColumns="repeat(5, 1fr)"
                            gap={4}>
                            <GridItem colSpan={3} maxH="240px !important">
                              <Text
                                color={"white"}
                                fontWeight={"semibold"}
                                fontSize={{ base: "18px", md: "20px" }}>
                                OMG Oh My Glow
                                <br />
                                Glow Kit
                              </Text>
                              <SimpleGrid
                                mt={{ base: 4, md: 8 }}
                                columns={{ md: 2 }}
                                spacing={{ base: 3, md: 4 }}>
                                <Box>
                                  <Button
                                    border={"1px solid white"}
                                    borderRadius="2xl"
                                    color={"white"}
                                    colorScheme="blackAlpha"
                                    _focus={{ boxShadow: "none" }}
                                    _hover={{
                                      bg: "white",
                                      color: "black"
                                    }}
                                    isFullWidth={true}
                                    onClick={handleTryAgain}>
                                    Coba Lagi
                                  </Button>
                                </Box>
                                <Box>
                                  <Button
                                    borderRadius="2xl"
                                    colorScheme="gray"
                                    isFullWidth={true}
                                    onClick={handleOpen}
                                    _focus={{ boxShadow: "none" }}
                                    _hover={{
                                      bg: "black",
                                      color: "white",
                                      border: "1px solid white"
                                    }}>
                                    Beli
                                  </Button>
                                </Box>
                              </SimpleGrid>
                            </GridItem>
                            <GridItem colSpan={2} maxH="240px !important">
                              <Image
                                float={"right"}
                                src={ProductsImage}
                                alt="product-result"
                                maxH="280px"
                                position="relative"
                                top={{ base: "-24px", lg: "-56px" }}
                                loading="lazy"
                              />
                              <div style={{ clear: "both" }}></div>
                            </GridItem>
                          </Grid>
                        </GridItem>
                      </Grid>
                    ) : (
                      <Grid p={4} minH="400px" templateColumns="repeat(4, 1fr)" gap={4}>
                        <GridItem colSpan={1}>
                          <Skeleton height={"100%"} />
                        </GridItem>
                        <GridItem colSpan={3}>
                          <Skeleton count={12} />
                        </GridItem>
                        <GridItem minH="200px" colSpan={4}>
                          <Skeleton height={"100%"} />
                        </GridItem>
                      </Grid>
                    )}
                  </Box>
                </Container>
              </QuizContainer>
            )}
          </>
        ) : (
          <QuizContainer>
            {statusQuiz === "success" && !_.isEmpty(quizDisplay) ? (
              <Box>
                <Center>
                  <Text
                    maxW="480px"
                    fontWeight="800"
                    textAlign="center"
                    fontSize={{ base: "18px", md: "24px" }}
                    px={{ base: 4, md: 0 }}
                    py={{ base: 16, md: 20 }}>
                    {quizDisplay.question}
                  </Text>
                </Center>
                <Container
                  pb={{ base: "120px", md: "64px" }}
                  maxW={{ lg: "container.md", xl: "container.xl" }}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 8, md: 10 }}>
                    {quizDisplay.choices.map((choice, idx) => (
                      <Box
                        key={idx}
                        onClick={() => {
                          handleClick(number, choice.points);
                        }}
                        transform="scale(1)"
                        transition="transform 500ms"
                        _hover={{ transform: "scale(1.04)", transition: "transform 500ms" }}
                        minH="80px"
                        borderRadius="2xl"
                        p={4}
                        boxShadow="0 4px 4px"
                        border="4px solid white">
                        <Text fontSize={{ base: "16px", md: "20px" }} textAlign="justify">
                          {choice.answer}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                </Container>
              </Box>
            ) : (
              <Center>
                <Spinner mt={"25vh"} />
              </Center>
            )}
          </QuizContainer>
        )}
        <Modal
          isOpen={modalShow}
          size={isMobile ? "sm" : "2xl"}
          onClose={() => handleClose()}
          isCentered={true}>
          <ProductModal PopUpData={popupData} />
        </Modal>
      </Box>
    </>
  );
};

export default SkincareQuizComponent;
